.rdrMonthAndYearWrapper {
    display: none !important;
    box-shadow: 0 3px 6px #00000012 !important;
    border-radius: 5px !important;
    align-items: center !important;

}

.rdrMonths {
    display: none !important;
    position: absolute !important;
    margin-top: 120px !important;
    z-index: 1000 !important;
    background-color: white !important;
    border-radius: 5px !important;
    box-shadow: 0 3px 6px #00000012 !important;

}

.rdrDateDisplayWrapper {
    width: 400px;
}

.flexImp {
    display: flex !important;
}